import React from "react";
import Typography from "../../components/Typography";

const Product = () => {
  return (
    <div>
      <Typography variant={"h2"}>Product</Typography>
    </div>
  );
};

export default Product;
